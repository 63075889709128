import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="top">
        <div className="item">
          <h1>Categories</h1>
          <ul>
            <Link className="link" to="/products">
              <li>Women</li>
            </Link>
            <Link className="link" to="/products">
              <li>Men</li>
            </Link>
            <Link className="link" to="/products">
              <li>Shoes</li>
            </Link>
            <Link className="link" to="/products">
              <li>Accessories</li>
            </Link>
            <Link className="link" to="/products">
              <li>New Arrivals</li>
            </Link>
          </ul>
        </div>
        <div className="item">
          <h1>Links</h1>
          <ul>
            <Link className="link" to="">
              <li>FAQ</li>
            </Link>
            <Link className="link" to="">
              <li>Pages</li>
            </Link>
            <Link className="link" to="">
              <li>Stores</li>
            </Link>
            <Link className="link" to="">
              <li>Compare</li>
            </Link>
            <Link className="link" to="">
              <li>Cookies</li>
            </Link>
          </ul>
        </div>
        <div className="item">
          <h1>About</h1>
          <span className="span">Welcome to our world of fashion: an e-commerce destination where style meets convenience. Find your perfect look from a wide selection of trendy, high-quality clothing for every occasion.</span>
        </div>
        <div className="item">
          <h1>Contact</h1>
          <span className="span">Got questions or need assistance? Our friendly team is here to help you with any inquiries about our products or your order. Reach out to us, and let's make your shopping experience seamless.</span>
        </div>
      </div>
      <div className="bottom">
        <div className="left">
          <Link className="link" to="/">
            <span className="logo">KuzeyArtist</span>
          </Link>
          <span className="copyright">© Copyright 2024. All Rights Reserved</span>
        </div>
        <div className="right">
          <img src="/img/payment.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
