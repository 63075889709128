import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import "./Categories.scss";
import useAppSelector from "../../hooks/useAppSelector";
import { Category } from "../../../features/categories/types/Category";
import useAppDispatch from "../../hooks/useAppDispatch";
import { getAllCategories } from "../../../features/categories/categoriesReducer";

const Categories = () => {
  const [items, setItems] = useState<Category[]>([]);
  const { categories } = useAppSelector((state) => state.categoriesReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllCategories());
    setItems(categories);
  }, [categories, dispatch]);

  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  const variants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const handleCategoryClick = (id: string) => {
    navigate(`/products/?category=${id}`);
  };
  return (
    <motion.div ref={ref} className="categories" variants={variants} initial="hidden" animate={inView ? "show" : "hidden"}>
      <div className="container">
        {items.map((item) => (
          <div className="col" key={item._id}>
            <div className="row" onClick={() => handleCategoryClick(item._id)}>
              <img src={item.images[0]} alt={item.name} />
              <span className="link">{item.name}</span>
            </div>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default Categories;
