import FeaturedProducts from "../../../features/products/components/FeaturedProducts/FeaturedProducts";
import Banner from "../../components/Banner/Banner";
import Categories from "../../components/Categories/Categories";
import Contact from "../../components/Contact/Contact";

const Home = () => {
  return (
    <div className="home">
      <Banner />
      <FeaturedProducts type="trending" />
      <Categories />
      <Contact />
    </div>
  );
};

export default Home;
