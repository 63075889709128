import { useNavigate } from "react-router-dom";
import { Button, CardMedia } from "@mui/material";
import { Box } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import "./Card.scss";
import { Product } from "../../../features/products/types/Product";
import useAppDispatch from "../../hooks/useAppDispatch";
import { addProductToCart } from "../../../features/cart/cartReducer";

const Card = ({ product }: { product: Product }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const quantity = 1;

  const onAddToCart = () => {
    if (product) dispatch(addProductToCart({ ...product, quantity: quantity }));
  };

  const handleProductClick = (id: string) => {
    navigate(`/products/${id}`);
  };

  return (
    <Box component={"div"} className="card">
      <Box component={"div"} className="image">
        {/* {product?.isNew && <span>New Season</span>} */}
        <CardMedia component="img" src={product?.images[0]} onClick={() => handleProductClick(product._id)} />
      </Box>
      <Box component={"div"} className="prices">
        <h2 className="product-title">{product?.name}</h2>
        <Box component={"div"} className="">
          <h3 className="old-price">${product?.price + 20}</h3>
          <h3 className="new-price">${product?.price}</h3>
        </Box>
      </Box>
      <Button onClick={onAddToCart} className="buttons">
        <AddShoppingCartIcon /> Add To Cart
      </Button>
    </Box>
  );
};

export default Card;
