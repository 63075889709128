import { useEffect } from "react";
import { motion } from "framer-motion";

import Card from "../../../../common/components/Card/Card";
import useAppDispatch from "../../../../common/hooks/useAppDispatch";
import "./FeaturedProducts.scss";
import useAppSelector from "../../../../common/hooks/useAppSelector";
import { getAllProducts } from "../../productsReducer";

const FeaturedProducts = ({ type }: { type: string }) => {
  const { products } = useAppSelector((state) => state.productsReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getAllProducts({
        page: 1,
        limit: "4",
      })
    );
  }, [dispatch]);

  const variants = {
    hidden: { opacity: 0, y: -100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <motion.div className="featuredProducts" initial="hidden" animate="visible" variants={variants}>
      <div className="top">
        <h1>{type} products</h1>
        <p>Discover the hottest trends in our Trending Clothes: a carefully selected mix of this season's must-have styles, from statement pieces to essential classics. Refresh your look now.</p>
      </div>
      <div className="bottom">
        {products.slice(0, 4).map((product, index) => (
          <Card key={`${product._id} + ${index}`} product={product} />
        ))}
      </div>
    </motion.div>
  );
};

export default FeaturedProducts;
