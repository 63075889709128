import { Box, Button, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import "./Banner.scss";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    triggerOnce: false,
  });

  const variants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const handleClick = () => {
    navigate("/products");
  };
  return (
    <Box ref={ref} className="Banner" component={motion.section} variants={variants} initial="hidden" animate={inView ? "show" : "hidden"}>
      <Box className="container">
        <Box className="Banner-content">
          <Typography className="Banner-content-span" variant="h5">
            Get the best products
          </Typography>
          <Typography className="Banner-content-title red" variant="h1">
            Sale 30%
          </Typography>
          <Typography className="Banner-content-title" variant="h1">
            Off Everything
          </Typography>
          <Button onClick={handleClick} className="banner-btn" variant="contained" color="primary">
            Shop now
          </Button>
        </Box>
        <img className="banner-img" src="https://images.pexels.com/photos/1926769/pexels-photo-1926769.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Banner" />
        <img className="banner-img" src="https://images.pexels.com/photos/1055691/pexels-photo-1055691.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Banner" />
      </Box>
    </Box>
  );
};

export default Banner;
